@font-face {
  font-family: 'RushfordClean';
  src: url('./fonts/Rushfordclean-rgz89.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNoteLight';
  src: url('./fonts/BebasNoteLight-LVEDg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'QueensidesMedium';
  src: url('./fonts/QueensidesMedium-x30zV.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* General Styles */
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(to bottom right, #55CDFC, #F7A8B8);
  font-family: 'Arial', sans-serif;
  padding: 0 20px; /* Padding for smaller screens */
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'RushfordClean', sans-serif;
}

/* Apply BebasNoteLight font to the description */
p {
  color: #666;
  font-size: 1.2rem;
  margin: 10px 0;
  font-family: 'BebasNoteLight', sans-serif;
}

.links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-family: 'QueensidesMedium', sans-serif;
}

.link-button {
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.twitter {
  background-color: #1DA1F2;
  color: white;
}

.twitter:hover {
  background-color: #0d8ddb;
}

.instagram {
  background-color: #E4405F;
  color: white;
}

.instagram:hover {
  background-color: #c32f4a;
}

.email {
  background-color: #34a853;
  color: white;
}

.email:hover {
  background-color: #2c8e43;
}

.telegram {
  background-color: #0088cc;
  color: white;
}

.telegram:hover {
  background-color: #006c99;
}

/* Responsive Design for Tablets (iPad) */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.1rem;
  }

  .link-button {
    padding: 8px 16px;
    font-size: 0.9rem;
    margin: 0 8px;
  }

  .avatar {
    width: 130px;
    height: 130px;
  }
}

/* Responsive Design for Mobile (Phones) */
@media (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
  }

  .links {
    flex-direction: column;
    align-items: center;
  }

  .link-button {
    margin: 10px 0; /* Vertical margin between buttons */
    padding: 8px 20px;
    width: 80%; /* Make buttons more prominent on small screens */
    text-align: center;
    font-size: 0.9rem;
  }
}
